function setCookie(name, value) {
  let timestamp = new Date();
  const days = 20 * 365;
  timestamp.setTime(timestamp.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + timestamp.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

document.getElementsByClassName("js--accept-cookies")[0].addEventListener('click', event => {
  event.preventDefault();
  setCookie("accept_hc_platform_cookies", "accepted");
  const banner = document.getElementsByClassName("js--cookie-banner")[0];
  banner.parentNode.removeChild(banner);
});
